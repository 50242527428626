import { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/solid';
import CurrencyFormat from 'react-currency-format';
import { useStateValue } from '../hooks/StateProvider';
import { getCartTotal } from '../hooks/reducer';
import StripeCheckout from 'react-stripe-checkout';
import Orders from './Orders';
import {Link} from 'react-router-dom'

import './checkout.css';

export default function Checkout() {
  const [{ cart }, dispatch] = useStateValue();

  console.log(cart);

  var paymentTotal = Number.parseFloat(
    (getCartTotal(cart) + getCartTotal(cart) * 0.0625).toFixed(2)
  );

  const removeFromCart = (id) => {
    dispatch({
      type: 'REMOVE_FROM_CART',
      id: id,
    });
  };

  // place current cart in local storage

  let storeCart = localStorage.setItem('currentCart', JSON.stringify(cart));

  console.log(JSON.parse(localStorage.getItem('currentCart')));

  // make payment and change to orders page

  const [ordersPage, setOrdersPage] = useState(false);
  console.log(ordersPage);

  const product = {
    name: 'Downloads',
    price: paymentTotal,
    productBy: 'Artsmyth Studio',
  };

  const makePayment = (token) => {
    const body = {
      token,
      product,
    };
    const headers = {
      'Content-Type': 'application/json',
    };

    return fetch(`https://us-central1-artsmythstudio-app-ad9be.cloudfunctions.net/app/payment`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log('RESPONSE ', response);
        const { status } = response;
        console.log('STATUS ', status);
        setOrdersPage(true);
      })
      .catch((error) => console.log(error));
  };

  if (!ordersPage) {
    return (
      <div>
        <div className="checkout__instuctions">
        <p className="checkout__instructins__title">Checkout Instructions:</p>
        <div className='checkout__instructions__items'>
          <p className='checkout__warning'>ATTENTION: Please do not close the site until after you have downloaded your images.</p>
        <p>1. Check the Order Summary.</p>
        <p>2. Click "Pay with Card".</p>
        <p>3. Enter card information and submit payment.</p>
        <p>4. WAIT for Orders page to load.</p>
        <p>4. Download images. </p>
        </div>
        </div>
      <div className="bg-gray-50">
        <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
            {/* Order summary */}
            <div className="checkout mt-10 lg:mt-0">
              <h2 className="orderSummary__title text-lg font-medium text-gray-900">
                Checkout: Order Summary
              </h2>
              
              <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                <h3 className="sr-only">Items in your cart</h3>
                <ul className="divide-y divide-gray-200">
                  {cart.map((product) => (
                    <li key={product.id} className="flex py-6 px-4 sm:px-6">
                      <div className="flex-shrink-0">
                        <img
                          src={product.imageSrc}
                          alt={product.imageAlt}
                          className="w-20 rounded-md"
                        />
                      </div>

                      <div className="ml-6 flex-1 flex flex-col">
                        <div className="flex">
                          <div className="min-w-0 flex-1">
                            <h4 className="checkoutStyle text-sm font-medium text-gray-700 hover:text-gray-800">
                              {product.name}
                            </h4>
                          </div>

                          <div className="ml-4 flex-shrink-0 flow-root">
                            <button
                              type="button"
                              className="-m-2.5 bg-white p-2.5 flex items-center justify-center text-gray-400 hover:text-gray-500"
                              onClick={() => removeFromCart(product.id)}
                            >
                              <span className="sr-only">Remove</span>
                              <TrashIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="flex-1 pt-2 flex items-end justify-between">
                          <p className="mt-1 text-sm font-medium text-gray-900">
                            ${product.price}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">Subtotal</dt>
                    <dd className="text-sm font-medium text-gray-900">
                      ${getCartTotal(cart)}
                    </dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-sm">Taxes</dt>
                    <dd className="text-sm font-medium text-gray-900">
                      <CurrencyFormat
                        value={Number.parseFloat(
                          getCartTotal(cart) * 0.0625
                        ).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </dd>
                  </div>
                  <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                    <dt className="text-base font-medium">Total</dt>
                    <dd className="text-base font-medium text-gray-900">
                      <CurrencyFormat
                        value={Number.parseFloat(
                          getCartTotal(cart) + getCartTotal(cart) * 0.0625
                        ).toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div>
              <div className="paymentMethod mt-10  pt-10">
                <h2 className="paymentMethod__title text-lg font-medium text-gray-900">
                  Checkout: Payment Method
                </h2>

                <StripeCheckout
                  stripeKey="pk_live_51KbvjaImObsOg2J8wAXK3t011c7eueCCq53GA4RryyIKsCFXfQEN9H3HimqySmGyS1hPWfWK6vKMhli3yhh7UogF00LgWqG3D4"
                  token={makePayment}
                  name="Artsmyth Studio"
                  amount={paymentTotal * 100}
                >
                  <button className="payWithCard__button inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Pay with Card</span>
                  </button>
                </StripeCheckout>

                <div className="orderTotal">
                  <CurrencyFormat
                    renderText={(value) => <h3>Order Total: {value}</h3>}
                    value={Number.parseFloat(
                      getCartTotal(cart) + getCartTotal(cart) * 0.0625
                    ).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  } else {
    return (
      <div>
        <Orders />
      </div>
    );
  }
}
