import React from 'react';
import { useNavigate } from 'react-router-dom';
import './thankYou.css';

// thank you for submitting a connection and return to store

const ThankYou = () => {
  const navigate = useNavigate();

  const handleReturnToStore = () => {
    console.log('you made it here');
    navigate('/');
  };
  return (
    <div className="pageStyle">
      <h1>Thank You!</h1>
      <p className="textAlign">
        Thank you for visiting our site and connecting with us. We will get back
        with you soon.
      </p>
      <div className="sm:col-span-2">
        <button
          type="submit"
          className="buttonStyle inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={handleReturnToStore}
        >
          Return to Store
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
