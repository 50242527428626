import { Link } from 'react-router-dom';
import './mobileNavigation.css';

const MobileNavigation = () => {
  return (
    <nav className="mobileNavigation">
      <ul>
        <Link to="/">
          <li>
            <p>Store</p>
          </li>
        </Link>
        <Link to="/about">
          <li>
            <p>About</p>
          </li>
        </Link>
        <Link to="/portraits">
          <li>
            <p>Portraits</p>
          </li>
        </Link>
        <Link to="/stilllife">
          <li>
            <p>Still Life</p>
          </li>
        </Link>
        <Link to="/landscapes">
          <li>
            <p>Landscapes</p>
          </li>
        </Link>
        <Link to="/nature/pets">
          <li>
            <p>Nature/Pets</p>
          </li>
        </Link>
        <Link to="/commissions">
          <li>
            <p>Prior Commissions</p>
          </li>
        </Link>
        <Link to="/shoppingcart">
          <li>
            <p>Shopping Cart</p>
          </li>
        </Link>
        <Link to="/checkout">
          <li>
            <p>Checkout</p>
          </li>
        </Link>
        <Link to="/connect">
          <li>
            <p>Connect</p>
          </li>
        </Link>
      </ul>
    </nav>
  );
};

export default MobileNavigation;
