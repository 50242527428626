import { useStateValue } from '../hooks/StateProvider';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import axios from 'axios';

import './shoppingCart.css';
import './orders.css';

// import { XIcon } from '@heroicons/react/solid';\
import CurrencyFormat from 'react-currency-format';
import { getCartTotal } from '../hooks/reducer';

export default function ShoppingCart() {
  // const [{ cart }, dispatch] = useStateValue();
  // console.log(cart);

  // console.log(cart);

  const cart = JSON.parse(localStorage.getItem('currentCart'));

  let navigate = useNavigate();
  const handleReturnToStore = () => {
    navigate('/');
  };

  function handleImageDownload(imageSrc) {
    axios({
      url: imageSrc,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'image.jpg');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Order
        </h1>
        <form className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <ul
              // role="list"
              className="border-t border-b border-gray-200 divide-y divide-gray-200"
            >
              {cart.map((product, productIdx) => (
                <li key={product.id} className="flex py-6 sm:py-10">
                  <div className="flex-shrink-0">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                    />
                  </div>

                  <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                      <div>
                        <div className="flex justify-between">
                          <h3 className="text-sm">
                            <a
                              href={product.href}
                              className="font-medium text-gray-700 hover:text-gray-800"
                            >
                              {product.name}
                            </a>
                          </h3>
                        </div>
                        <p className="cartStyle mt-1 text-sm font-medium text-gray-900">
                          ${product.price}
                        </p>
                      </div>

                      <div className="mt-4 sm:mt-0 sm:pr-9">
                        <div className="absolute top-0 right-0">
                          {/* <a href={product.imageSrc} download> */}
                          <button
                            type="button"
                            className="addToCartButton inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            onClick={() =>
                              handleImageDownload(product.imageSrc)
                            }
                          >
                            Download
                          </button>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
          >
            <h2
              id="summary-heading"
              className="text-lg font-medium text-gray-900"
            >
              Order summary
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="flex items-center justify-between">
                <dt className="text-sm text-gray-600">Subtotal</dt>
                <dd className="text-sm font-medium text-gray-900">
                  <CurrencyFormat
                    value={Number.parseFloat(getCartTotal(cart)).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="flex text-sm text-gray-600">
                  <span>Tax estimate</span>
                </dt>
                <dd className="text-sm font-medium text-gray-900">
                  <CurrencyFormat
                    value={Number.parseFloat(
                      getCartTotal(cart) * 0.0625
                    ).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="text-base font-medium text-gray-900">
                  Total Paid
                </dt>
                <dd className="text-base font-medium text-gray-900">
                  <CurrencyFormat
                    value={Number.parseFloat(
                      getCartTotal(cart) + getCartTotal(cart) * 0.0625
                    ).toFixed(2)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </dd>
              </div>
            </dl>
          </section>
        </form>

        <button
          type="button"
          className="returnToStoreButton inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={() => handleReturnToStore()}
        >
          Return to Store
        </button>
      </div>
    </div>
  );
}
