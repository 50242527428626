import { useState, useEffect } from 'react';
import './products.css';
import { useStateValue } from '../../hooks/StateProvider';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import Modal from 'react-modal/lib/components/Modal';

export default function Nature() {
  const [{ cart }, dispatch] = useStateValue();
  console.log(cart);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);

  const handleEnlargeImage = (imageSrc) => {
    setEnlargedImage(imageSrc);
    setModalIsOpen(true);
    console.log(imageSrc);
  };

  const addToCart = (id, name, imageSrc, imageAlt, price) => {
    dispatch({
      type: 'ADD_TO_CART',
      item: {
        id: id,
        name: name,
        imageSrc: imageSrc,
        imageAlt: imageAlt,
        price: price,
      },
    });
  };

  const [nature, setNature] = useState([]);

  useEffect(() => {
    db.collection('nature')
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        // everytime a new product is added this function is called
        setNature(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            product: doc.data(),
          }))
        );
      });
  }, []);

  return (
    <div className="nature bg-white">
      <div className="bg-white">
        <div className="text max-w-2xl mx-auto py-16 px-4 sm:py-5 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            Nature/Pets
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {nature.map(({ id, product }) => (
              <div key={id} className="group relative">
                <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden lg:h-80 lg:aspect-none">
                  <img
                    src={product.imageURL}
                    alt={product.imageAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">{product.caption}</h3>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    ${product.price}
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="addToCartButton inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={() => handleEnlargeImage(product.imageURL)}
                  >
                    Enlarge Image
                  </button>
                  <button
                    type="button"
                    className="addToCartButton inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={() =>
                      addToCart(
                        id,
                        product.caption,
                        product.imageURL,
                        product.imageAlt,
                        product.price
                      )
                    }
                  >
                    Add to Cart
                  </button>
                  <Link to="/">
                    <button
                      type="button"
                      className="addToCartButton inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Return to Store
                    </button>
                  </Link>
                  <Link to="/shoppingcart">
                    <button
                      type="button"
                      className="addToCartButton inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Shopping Cart
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen}>
        <div className="modal">
          <img src={enlargedImage} alt="" />

          <button
            type="button"
            className="addToCartButton inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={() => setModalIsOpen(false)}
          >
            close window
          </button>
        </div>
      </Modal>
    </div>
  );
}
