import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { initializeApp } from '@firebase/app';
import { getStorage } from '@firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyA99TfJ-eShWfRr5czPQmQUmY8ZIsRucjY',
  authDomain: 'artsmythstudio-app-ad9be.firebaseapp.com',
  projectId: 'artsmythstudio-app-ad9be',
  storageBucket: 'artsmythstudio-app-ad9be.appspot.com',
  messagingSenderId: '263055607639',
  appId: '1:263055607639:web:e3ab02a2b0f2324ec3e012',
  measurementId: 'G-YJZ2Y9S9KF',
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyA99TfJ-eShWfRr5czPQmQUmY8ZIsRucjY',
  authDomain: 'artsmythstudio-app-ad9be.firebaseapp.com',
  projectId: 'artsmythstudio-app-ad9be',
  storageBucket: 'artsmythstudio-app-ad9be.appspot.com',
  messagingSenderId: '263055607639',
  appId: '1:263055607639:web:e3ab02a2b0f2324ec3e012',
  measurementId: 'G-YJZ2Y9S9KF',
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
// const storage = firebase.getStorage();

export { db, auth };
