import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';

import './Storefront.css';

export default function Storefront() {
  const [viewCollections, setViewCollections] = useState([]);

  useEffect(() => {
    db.collection('viewCollections')
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        // everytime a new product is added this function is called
        setViewCollections(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            product: doc.data(),
          }))
        );
      });
  }, []);

  const [myFavorites, setMyFavorites] = useState([]);

  useEffect(() => {
    db.collection('myFavorites')
      .orderBy('timestamp', 'desc')
      .onSnapshot((snapshot) => {
        // everytime a new product is added this function is called
        setMyFavorites(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            product: doc.data(),
          }))
        );
      });
  }, []);

  return (
    <div className="storeFront bg-white">
      <div>
        <h1>Welcome</h1>
        <p className="welcomeText">
          We are excited to welcome you to Artsmyth Studio - the web home of our
          resident artist, Debbi Barr. Feel free to linger; browse our pages and
          find exactly that for which you are looking. Discover the perfect
          print - the one that inspires, capturing your imagination. Then
          download your find to make a print for your home or use it in your
          next project. Our customers have framed Debbi's images to adorn their
          walls. Others have made customized cards to send their greetings or
          give as gifts. Once downloaded, the image is yours to create whatever
          your mind can imagine. (For best results, we recommend using a
          professional printer for your prints.)
        </p>
        <p className="questions connectStyle">
          If you have questions, we'd love to hear from you (
          <Link to="/connect">
            <a>connect</a>
          </Link>
          ).{' '}
        </p>
      </div>
      <div className="bg-white">
        <div className="text max-w-2xl mx-auto py-16 px-4 sm:py-5 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            View Collections
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {viewCollections.map(({ id, product }) => (
              <div key={id} className="group relative">
                <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <Link to={product.caption}>
                    <img
                      src={product.imageURL}
                      alt={product.imageAlt}
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  </Link>
                </div>
                <div className="mt-4 flex justify-between">
                  <div className="socks__caption">
                    <Link to={product.caption}>
                      <h3 className="text-sm text-gray-700">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product.caption}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="text max-w-2xl mx-auto py-16 px-4 sm:py-5 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            My Favorites
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {myFavorites.map(({ id, product }) => (
              <div key={id} className="group relative">
                <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <Link to="/nature">
                    <img
                      src={product.imageURL}
                      alt={product.imageAlt}
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  </Link>
                </div>
                <div className="mt-4 flex justify-between">
                  <div className="socks__caption">
                    <Link to={product.caption}>
                      <h3 className="text-sm text-gray-700">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product.caption}
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
