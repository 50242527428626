import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Storefront from './pages/Storefront';
import About from './pages/About';
import Portraits from './pages/products/Portraits';
import StillLife from './pages/products/StillLife';
import Landscapes from './pages/products/Landscapes';
import Nature from './pages/products/Nature';
import PriorCommissions from './pages/products/PriorCommissions';
import ShoppingCart from './pages/ShoppingCart';
import Checkout from './pages/Checkout';
import Orders from './pages/Orders';
import Connect from './pages/Connect';
import ThankYou from './pages/ThankYou';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import AdminLogin from './components/AdminLogin';
import './App.css';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const promise = loadStripe('pk_live_51KbvjaImObsOg2J8wAXK3t011c7eueCCq53GA4RryyIKsCFXfQEN9H3HimqySmGyS1hPWfWK6vKMhli3yhh7UogF00LgWqG3D4');




function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<Storefront />}></Route>
          <Route exact path="/adminlogin" element={<AdminLogin />}></Route>
          <Route exact path="about" element={<About />}></Route>
          <Route path="portraits" element={<Portraits />}></Route>
          <Route path="stills" element={<StillLife />}></Route>
          <Route path="landscapes" element={<Landscapes />}></Route>
          <Route path="nature/pets" element={<Nature />}></Route>
          <Route path="commissions" element={<PriorCommissions />}></Route>
          <Route path="shoppingcart" element={<ShoppingCart />}></Route>
          <Route
            path="checkout"
            element={
              <Elements stripe={promise}>
                {' '}
                <Checkout />{' '}
              </Elements>
            }
          ></Route>
          <Route path="orders" element={<Orders />}></Route>
          <Route path="connect" element={<Connect />}></Route>
          <Route path="thankyou" element={<ThankYou />}></Route>
          <Route path="footer" element={<Footer />}></Route>
          <Route path="navigation" element={<Navigation />}></Route>
        </Routes>
        <Footer />
      </Router>{' '}
    </div>
  );
}

export default App;
