import './about.css';

import debbiArtSupplyFrame from '../assets/images/debbiArtSupplyFrame.jpg';
import matadorDetails from '../assets/images/Matador-Final Details.jpg';
import debbiFrame from '../assets/images/debbi holding a framed picture.jpg';

import eyes1 from '../assets/images/eyes 1.jpg';
import eyes2 from '../assets/images/eyes 2.jpg';
import eyes3 from '../assets/images/eyes 3.jpg';
import eyes4 from '../assets/images/eyes 4.jpg';
import eyes5 from '../assets/images/eyes 5.jpg';
import eyes6 from '../assets/images/eyes 6.png';
import eyes7 from '../assets/images/eyes 7.jpg';
import eyes8 from '../assets/images/eyes 8.jpg';
import eyes9 from '../assets/images/eyes 9.jpg';

const images = [
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes1,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes2,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes3,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes4,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes5,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes6,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes7,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes8,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
  {
    id: Math.floor(Math.random() * 100000),
    imageSrc: eyes9,
    imageAlt: 'a collage of human faces focusing on the eyes',
  },
];

const features = [
  {
    name: 'one-of-a-kind ...',
    description:
      "Debbi's art evokes both delight and deep thought from the viewer. With great skill and thoughtfulness, she conveys her love of creation and the Creator in her pieces. I don't believe I've ever gazed at one of her works of art and not experienced God speaking through the subject, be it through the eyes of a beautifully painted child or through the intimacy of a lion's mane. Her work is one-of-a kind and the care with which she creates each piece is second to none.",
  },
  {
    name: 'a touch of whimsy ...',
    description:
      'David and I think your artwork is at times spontaneous and always relevant, colorful, with attention to detail, and sometimes it exhibits a touch of whmisy. Your subject matter is personal but at the same time wide and varied. You are always embracing opportunities to learn and practice new techniques. We just know we marvel at your God-given talent.',
  },
  {
    name: 'your work looks within ...',
    description:
      'Your skill and artwork sees things beyond. The details of eyelashes. The range of shades of color. The beyond. When others quickly see the whole picture - your work looks within.',
  },
];

export default function About() {
  return (
    <div className="about">
      <div className="horizontalScroll">
        {images.map((image) => (
          <div className="">
            <img
              src={image.imageSrc}
              alt={image.imageAlt}
              className="imageStyle"
            />
          </div>
        ))}
      </div>

      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-10 px-4 sm:py-5 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="grid items-center grid-cols-1 gap-y-16 gap-x-8 lg:grid-cols-2">
            <div>
              <div className="">
                <p className="meetStyle mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Meet our artist in residence ...
                </p>
                <p className="aboutText">
                  Debbi Barr was born with an amazing artist’s perspective,
                  experiencing the world through a lens of nuanced color, shade,
                  and perspective. From an early age, she expressed herself
                  through art. Imagine the surprise of her first-grade teacher
                  when Debbi’s math homework was adorned with detailed pencil
                  drawings. By age eight, she was creating pieces from her own
                  art room filled with all the necessary supplies to explore and
                  expand her creative genius.{' '}
                </p>

                <p className="aboutText">
                  Growing up on a farm provided the perfect foundation for the
                  inspiration that underpins her work. Creation and all its
                  beauty capture her imagination and drive her expressive
                  efforts. She’s awed by the wonder of nature and the beauty of
                  life in its many forms. You see in her portraits, landscapes,
                  and still life creations an inimitable perspective, which
                  captures the unique, one-of-a-kind, quality of each setting .
                  She works in a wide range of mediums but her favorites are
                  graphite, ink, charcoal, and pastels.{' '}
                </p>

                <p className="aboutText">
                  Debbi lives in the Chicagoland area with her husband and
                  Auggie – a loveable Aussiedoodle. They have three married
                  adult children and six grandchildren.
                </p>

                <p className="aboutText">
                  We are pleased you are visiting our website – take your time,
                  explore Debbi's work, and experience all that her creations
                  inspire.
                </p>
              </div>
              <div className="">
                <p className="sayingStyle mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  What folks are saying ...
                </p>
              </div>

              <dl className="mt-5 space-y-3">
                {features.map((feature) => (
                  <div key={feature.name}>
                    <dt className="text-sm font-medium text-gray-900">
                      {feature.name}
                    </dt>
                    <dd className="aboutText mt-3 text-sm text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div>
              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <img
                  src={debbiArtSupplyFrame}
                  alt="Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system."
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4 sm:gap-6 sm:mt-6 lg:gap-8 lg:mt-8">
                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                  <img
                    src={matadorDetails}
                    alt="Detail of temperature setting button on kettle bass with digital degree readout."
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                  <img
                    src={debbiFrame}
                    alt="Kettle spout pouring boiling water into coffee grounds in pour-over mug."
                    className="w-full h-full object-center object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
