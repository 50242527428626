import React from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

function DeleteProduct({
  collectionDelete,
  currentId,
  currentImageURL,
  currentCaption,
  currentPrice,
}) {
  async function handleDeleteProduct() {
    await deleteDoc(doc(db, collectionDelete, currentId));
    window.location.reload(true);
  }

  const handleReturnToAdmin = () => {
    window.location.reload(true);
  };

  return (
    <div className="portraits bg-white">
      <div className="delete__page bg-white">
        <div className="delete__page__contents text max-w-2xl mx-auto py-16 px-4 sm:py-5 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="delete__title text-3xl font-extrabold tracking-tight text-gray-900">
            Product to Delete
          </h2>

          <div className="center__contents mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            <div>
              <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden lg:h-80 lg:aspect-none">
                <img
                  src={currentImageURL}
                  alt=""
                  className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
              </div>
              <div className="delete___caption-price mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">{currentCaption}</h3>
                </div>
                <p className="text-sm font-medium text-gray-900">
                  ${currentPrice}
                </p>
              </div>
              <div classname="buttonAlign">
                <div>
                  <button
                    type="button"
                    className="deleteProduct__button inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={handleDeleteProduct}
                  >
                    Delete Product
                  </button>
                </div>
                <div className="returnToAdmin__button">
                  <button
                    type="button"
                    className="returnToAdmin__button inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    onClick={handleReturnToAdmin}
                  >
                    Return to Admin Panel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteProduct;
