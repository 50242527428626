import { Link } from 'react-router-dom';

const navigation = {
  main: [
    { name: 'Store', link: '/' },
    { name: 'About', link: '/about' },
    { name: 'Portraits', link: '/portraits' },
    { name: 'Stills', link: '/stills' },
    { name: 'Landscapes', link: '/landscapes' },
    { name: 'Nature/Pets', link: '/nature/pets' },
    { name: 'Prior Commissions', link: '/commissions' },
    { name: 'Shopping Cart', link: '/shoppingcart' },
    { name: 'Checkout', link: '/checkout' },
    { name: 'Connect', link: '/connect' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/debragbarrart',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/barr.debbi/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.etsy.com/shop/ArtsmythStudio?ref=shop_sugg',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 -5 26 38" {...props}>
          <path
            fillRule="evenodd"
            d="M8.094 2.859v10.234c3.625 0.031 5.531-0.156 5.531-0.156 1.469-0.047 1.687-0.422 2.031-1.859l0.516-2.219h1.609l-0.219 5.031 0.109 4.984h-1.609l-0.453-1.984c-0.328-1.484-0.969-1.844-2.016-1.859 0 0-1.344-0.125-5.5-0.125v8.687c0 1.625 0.891 2.391 2.766 2.391h5.578c1.875 0 3.563-0.187 4.719-2.859l1.453-3.375h1.391c-0.109 0.672-0.859 6.875-0.969 8.25-5.141-0.187-7.344-0.187-7.344-0.187h-9.812l-5.875 0.187v-1.594l1.984-0.391c1.391-0.266 1.813-0.672 1.828-1.813 0.094-3.781 0.125-10.047 0.125-10.047s0.047-6.281-0.125-10.078c-0.047-1.297-0.438-1.609-1.828-1.875l-1.984-0.375v-1.594l5.875 0.187h10.969s2.172 0 5.844-0.422c-0.219 2.391-0.484 7.906-0.484 7.906h-1.453l-0.5-1.937c-0.609-2.422-1.422-3.719-2.922-3.719h-8.563c-0.641 0-0.672 0.219-0.672 0.609z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/artsmyth_studio',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/in/debra-barr-3434014b/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 75 75" {...props}>
          <path
            data-name="layer1"
            fill="#949494"
            d="M1.15 21.7h13V61h-13zm46.55-1.3c-5.7 0-9.1 2.1-12.7 6.7v-5.4H22V61h13.1V39.7c0-4.5 2.3-8.9 7.5-8.9s8.3 4.4 8.3 8.8V61H64V38.7c0-15.5-10.5-18.3-16.3-18.3zM7.7 2.6C3.4 2.6 0 5.7 0 9.5s3.4 6.9 7.7 6.9 7.7-3.1 7.7-6.9S12 2.6 7.7 2.6z"
          ></path>{' '}
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-4">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-4 py-2">
              <Link
                to={item.link}
                className="text-base text-gray-500 hover:text-gray-900"
              >
                <p>{item.name}</p>
              </Link>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-4">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; 2022 dropBy, LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
