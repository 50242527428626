// import { PlusSmIcon } from '@heroicons/react/solid';
import { ShoppingCart } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useStateValue } from '../hooks/StateProvider';
import MobileNavigation from './MobileNavigation';
import { CgMenu } from 'react-icons/cg';
import { useState } from 'react';

import './navigation.css';

export default function Navigation() {
  const [{ cart }] = useStateValue();

  const [open, setOpen] = useState(false);

  return (
    <div className="fixPosition  mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16">
        <div className="flex">
          <div className="-ml-2 mr-2 flex items-center md:hidden"></div>
          <div className="flex-shrink-0 flex items-center">
            <Link to="/">
              <span className="inline align-middle text-2xl tracking-tight font-bold text-gray-600">
                ArtsmythStudio
              </span>
            </Link>
          </div>
          <div className="navMobileHide hidden md:ml-6 md:flex md:space-x-4">
            {/* Current: "border-green-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
            <Link
              to="/"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Store</p>
            </Link>
            <Link
              to="/about"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>About</p>
            </Link>
            <Link
              to="/portraits"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Portraits</p>
            </Link>
            <Link
              to="/stills"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Stills</p>
            </Link>
            <Link
              to="/landscapes"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Landscapes</p>
            </Link>
            <Link
              to="/nature/pets"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Nature/Pets</p>
            </Link>
            <Link
              to="commissions"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Prior Commissions</p>
            </Link>
            <Link
              to="/shoppingcart"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Shopping Cart</p>
            </Link>
            <Link
              to="/checkout"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Checkout</p>
            </Link>
            <Link
              to="/connect"
              className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <p>Connect</p>
            </Link>
          </div>
        </div>
        <div className="header__optionBasket">
          <Link to="/shoppingcart">
            <ShoppingCart />
            <span className="header__optionLineTwo header__basketCount">
              {' '}
              {cart?.length}
            </span>
          </Link>
          <CgMenu className="hamburger" onClick={() => setOpen(!open)} />
        </div>
      </div>
      {open && <MobileNavigation />}
    </div>
  );
}
