import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import Admin from '../pages/Admin';
import './adminLogin.css';

const FillinAuth = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [user, setUser] = useState(null);

  const signIn = (event) => {
    event.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => alert(error.message));
  };

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // user has logged in ...
        setUser(true);
        console.log(user);
      } else {
        // user has logged out ...
        setUser(false);
        console.log(user);
      }
    });
    // return () => {
    //   // perform some cleanup actions
    //   unsubscribe();
    // };
  }, [user]);

  if (!user) {
    return (
      <div className="auth">
        <h1 className="auth__title">Admin Login</h1>
        <p className="auth__text">Please sign up or sign in ...</p>
        <form className="signIn__signUp ">
          <input
            className="email__input"
            placeholder="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className="password__input"
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div>
            <button type="submit" className="signIn__button" onClick={signIn}>
              SIGN IN
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return <Admin />;
  }
};

export default FillinAuth;
