import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { storage, db } from '../firebase';
import firebase from 'firebase/compat/app';
import { getDownloadURL, ref, uploadBytesResumable } from '@firebase/storage';
import { auth } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import DeleteProduct from '../components/DeleteProduct';

import './admin.css';

const Admin = () => {
  const [collectionAdd, setCollectionAdd] = useState('');
  const [caption, setCaption] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);

  const [collectionDelete, setCollectionDelete] = useState('');
  const [captionDelete, setCaptionDelete] = useState('');

  const [currentId, setCurrentId] = useState('');
  const [currentImageURL, setCurrentImageURL] = useState('');
  const [currentCaption, setCurrentCaption] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');

  const [deleteProduct, setDeleteProduct] = useState(false);

  const handleChooseFile = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    console.log(image.name);
    const storageRef = ref(storage, `/images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
        alert(error.message);
      },
      () => {
        // .ref('images')
        // .child(image.name)
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // post image inside db
          db.collection(collectionAdd).add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            caption: caption,
            imageURL: url,
            price: price,
          });

          setProgress(0);
          setCaption('');
          setImage(null);
          setPrice('');
        });
      }
    );
  };

  async function handleSelectProduct() {
    console.log(collectionDelete);
    console.log(captionDelete);
    const q = query(
      collection(db, collectionDelete),
      where('caption', '==', captionDelete)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, ' => ', doc.data());

      setCurrentId(doc.id);
      console.log(currentId);
      setCurrentImageURL(doc.data().imageURL);
      console.log(doc.data().imageURL);
      setCurrentCaption(doc.data().caption);
      console.log(doc.data().caption);
      setCurrentPrice(doc.data().price);
      console.log(doc.data().price);
    });

    setDeleteProduct(true);
  }

  // async function handleDeleteProduct() {
  //   await deleteDoc(doc(db, collectionDelete, currentId));
  //   setDeleteProduct(false);
  // }

  // const handleReturnToAdmin = () => {
  //   setCaptionDelete('');
  //   setDeleteProduct(false);
  // };

  const handleLogout = (event) => {
    event.preventDefault();
    auth.signOut().catch((error) => alert(error.message));
  };
  if (!deleteProduct) {
    return (
      <div className="adminPanel_Page">
        <div>
          <h1>Admin Panel</h1>
          <form className="upload__form">
            <h3 className="admin__h3">Add a Product ...</h3>

            <h3>Select a Collection:</h3>

            <div className="collection__selector">
              <div>
                <input
                  type="radio"
                  id="viewCollections"
                  value="viewCollections"
                  name="select_collection"
                  onClick={() => setCollectionAdd('viewCollections')}
                />
                <label for="viewCollections"> View Collections</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="myFavorites"
                  value="myFavorites"
                  name="select_collection"
                  onClick={() => setCollectionAdd('myFavorites')}
                />
                <label for="myFavorites"> My Favorites</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="portraits"
                  value="portraits"
                  name="select_collection"
                  onClick={() => setCollectionAdd('portraits')}
                />
                <label for="portraits"> Portraits </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="stillLife"
                  value="stillLife"
                  name="select_collection"
                  onClick={() => setCollectionAdd('stillLife')}
                />
                <label for="stillLife"> Still Life </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="landscapes"
                  value="landscapes"
                  name="select_collection"
                  onClick={() => setCollectionAdd('landscapes')}
                />
                <label for="landscapes"> Landscapes </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="nature"
                  value="nature"
                  name="select_collection"
                  onClick={() => setCollectionAdd('nature')}
                />
                <label for="nature"> Nature </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="priorCommissions"
                  value="priorCommissions"
                  name="select_collection"
                  onClick={() => setCollectionAdd('priorCommissions')}
                />
                <label for="priorCommissions"> Prior Commissions </label>
              </div>
            </div>

            <div>
              <input
                type="text"
                placeholder="Enter a caption ..."
                onChange={(event) => setCaption(event.target.value)}
                value={caption}
                className="upload__caption"
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Enter a price ..."
                onChange={(event) => setPrice(event.target.value)}
                value={price}
                className="upload__price"
              />
            </div>
            <div className="choose__file">
              <input type="file" onChange={handleChooseFile} />
            </div>
            <div className="upload__button">
              <Button onClick={handleUpload}>Upload</Button>
            </div>
            <div className="upload__progressBar">
              <progress
                className="imageUpload__progress"
                value={progress}
                max="100"
              />
            </div>
          </form>
        </div>
        <hr />
        <div>
          <form className="upload__form">
            <h3 className="admin__h3">Delete a Product ...</h3>

            <h3>Select a Collection:</h3>

            <div className="collection__selector">
              <div>
                <input
                  type="radio"
                  id="viewCollections"
                  value="viewCollections"
                  name="select_collection"
                  onClick={() => setCollectionDelete('viewCollections')}
                />
                <label for="viewCollections"> View Collections</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="myFavorites"
                  value="myFavorites"
                  name="select_collection"
                  onClick={() => setCollectionDelete('myFavorites')}
                />
                <label for="myFavorites"> My Favorites</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="portraits"
                  value="portraits"
                  name="select_collection"
                  onClick={() => setCollectionDelete('portraits')}
                />
                <label for="portraits"> Portraits </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="stillLife"
                  value="stillLife"
                  name="select_collection"
                  onClick={() => setCollectionDelete('stillLife')}
                />
                <label for="stillLife"> Still Life </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="landscapes"
                  value="landscapes"
                  name="select_collection"
                  onClick={() => setCollectionDelete('landscapes')}
                />
                <label for="landscapes"> Landscapes </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="nature"
                  value="nature"
                  name="select_collection"
                  onClick={() => setCollectionDelete('nature')}
                />
                <label for="nature"> Nature </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="priorCommissions"
                  value="priorCommissions"
                  name="select_collection"
                  onClick={() => setCollectionDelete('priorCommissions')}
                />
                <label for="priorCommissions"> Prior Commissions </label>
              </div>
            </div>

            <div>
              <input
                type="text"
                placeholder="Enter a caption ..."
                onChange={(event) => setCaptionDelete(event.target.value)}
                value={captionDelete}
                className="upload__caption"
              />
            </div>

            <button
              type="button"
              className="selectProduct__button inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={handleSelectProduct}
            >
              Select Product
            </button>
          </form>
          <hr />
          <button
            type="button"
            className="adminLogout__button inline-flex items-center px-1 py-.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <DeleteProduct
          collectionDelete={collectionDelete}
          currentId={currentId}
          currentImageURL={currentImageURL}
          currentCaption={currentCaption}
          currentPrice={currentPrice}
        />
      </div>
    );
  }
};

export default Admin;
